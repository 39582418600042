.provision-device-container {
  background-color: white;
  color: black !important;
  min-height: 100vh;
  padding-top: 1rem;
}

.my-dropdown {
  width: 300px;
}

.my-btn {
  width: 300px;
  height: 40px;
  margin: 0px auto;
  background-color: rgb(16, 72, 175);
  text-decoration: none;
  padding-top: 10px;
}
.my-btn:hover {
  color: white;
  background-color: rgb(39, 87, 177);
}

.run-btn {
  width: 300px;
  margin: 0 auto;
  background-color: #f04f4f;
}
.run-btn:hover {
  background-color: #fc6666;
}

.my-dropdown-menu {
  max-height: 300px;
  overflow: auto;
  background-color: rgb(197, 217, 255);
}

.row {
  margin-right: unset;
  margin-left: unset;
}

.result-div {
  white-space:pre-wrap;
  text-align: left;
  margin: 20px 100px 0 50px;
  background: #d6d6d6;
  padding: 10px 10px 10px 20px;
  font-family: monospace;
}
.dropzone {
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding: 40px 20px;
    text-align: center;
    cursor: pointer;
    margin: 40px 20px;
}

.dropzone.active {
    border-color: #007bff;
}

.upload-spinner {
    margin: 0 auto;
}

.error-message {
    color: red;
}

.success-message {
    color: green;
}

.provision-device-container {
  background-color: white;
  color: black !important;
  min-height: 100vh;
  padding-top: 1rem;
}

.weekly-report-header {
  background: rgb(197, 217, 255);
  margin-bottom: 1rem;
}

.weekly-report-tbl-label {
  margin-right: 10px;
}

.weekly-report-tbl-select {
  margin-right: 10px;
  height: 30px;
  min-width: 70px;
}

.weekly-report-tbl-button {
  min-width: 100px;
  padding: 0px;
  height: 30px;
  margin-bottom: 5px;
}

.weekly-report-spinner {
  margin: 0 auto;
}

.weekly-report-row {
  line-height: 20px;
}
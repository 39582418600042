body{
  /* background-color:#5e91ff; */
  font-size:14px;
  color:#fff;
}
.container {
  background-color: white;
  color: black !important;
  /* height: calc(100vh - 52px); */
}
.simple-login-container{
  width:300px;
  max-width:100%;
  margin:50px auto;
}
.simple-login-container h2{
  text-align:center;
  font-size:20px;
}

.simple-login-container .btn-login{
  background-color:#FF5964;
  color:#fff;
}
a{
  color:#fff;
}
.report-header {
    background-color: rgb(197, 217, 255);
    margin-bottom: 1rem;
}

.report-search-box {
    height: 30px;
    width: 300px;
    margin-right: 10px;
    margin-left: 10px;
}

.report-button {
    min-width: 100px;
    padding: 0;
    height: 30px;
    margin-bottom: 5px;
}

.report-button-download {
    line-height: 20px;
}

.report-spinner {
    margin: 0 auto;
}

@import 'bootstrap/dist/css/bootstrap.min.css';

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 170px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 10px 10px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {background-color: rgb(121, 119, 116);}

.dropdown:hover .dropdown-content {
  display: block;
  z-index: 9999999;
}

.nav-item {
  color: rgba(255,255,255,.55) !important;
  background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity));
  text-decoration: none;
}

.nav-item:hover {
  background-color: rgb(121, 119, 116) !important;
  cursor: pointer;
}

.nav-active {
  color: blue;
}

body {
  background-color: white;
}

.mr-auto {
  margin-right: auto !important;
}

.person-email {
  padding: 1rem;
  height: 48px;
  color: rgba(255,255,255,.55)
}

.nav-link:hover {
  background-color: rgb(121, 119, 116);
  cursor: pointer;
}

.nav-link {
  padding: 1rem;
  height: 48px;
}

.nav-link-active {
  background-color: rgb(121, 119, 116);
}

.ml-auto {
  margin-left: auto !important;
}

.my-navbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 2rem;
  height: 48px;
}

.header {
  height: 50px;
  background-color: rgb(117, 117, 117);
  font-weight: bold;
  font-size: 30px;
  --bs-gutter-x: none;
  --bs-gutter-y: none;
}

.body-container {
  padding: unset;
  /* padding-left: 5px; */
}

.nav-sidebar {
  background-color: rgb(197, 217, 255) !important;
}

.App {
  text-align: center;
}

.app-container {
  --bs-gutter-x: none;
  --bs-gutter-y: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
